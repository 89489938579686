<template>
    <div class="content">
        <div class="tool">
           <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-button type="primary" class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>

        <treetable style='margin-top: 15px;overflow-y: scroll;height: calc(100vh - 150px);'  :data='TreeGateListData' :columns='columns' border tree-type :is-fold='false' :selection-type='false' :show-index='false' :expand-type='false'>
            <template slot="gtType" slot-scope="scope">
              {{scope.row.gt_type===0?'入口':(scope.row.gt_type===1?'出口':'')}}
            </template>
            <template slot="handleEvent" slot-scope="scope">
              <div v-if="scope.row.gt_id!==0">
                <el-link type="primary" @click="Edit(scope.row)">修改</el-link>
                <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                <el-link type="warning" @click="TimeControl(scope.row)">时段限制</el-link>
              </div>
            </template>
        </treetable>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='1100px' :append-to-body='true'>
            <el-form :model="gateForm" :rules="rules" ref="gateForm" label-width="120px" size='mini'>
              <el-collapse accordion v-model="activeNames">
                <el-collapse-item name="1">
                  <template slot="title">
                    <span style="font-size:13px;color:#409EFF"> 通道基本信息</span>
                  </template>
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="8" >
                        <el-form-item label="名称" prop="gt_name">
                            <el-input v-model="gateForm.gt_name" ></el-input>
                        </el-form-item>
                        <el-form-item label="通道编号" >
                          <el-input-number v-model="gateForm.gt_no" controls-position="right" :min="1" :max="1000" style="width:100%;"></el-input-number>
                        </el-form-item>
                        <el-form-item label="显示屏" >
                          <el-select v-model="gateForm.gt_led_type" style="width:100%;">
                              <el-option label="Led" :value='0'></el-option>
                              <el-option label="Lcd" :value='1'></el-option>
                          </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="通道类型">
                          <el-select v-model="gateForm.gt_type" style="width:100%;" @change="GateTypeChanged">
                              <el-option label="入口" :value='0'></el-option>
                              <el-option label="出口" :value='1'></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="所属车场" >
                          <el-select v-model="gateForm.gt_pk_id" style="width:100%;">
                              <el-option label="无" :value='0'></el-option>
                              <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item v-if="gateForm.gt_led_type===0" label="Led显示">
                          <el-select v-model="gateForm.gt_led_model" style="width:100%;">
                              <el-option label="大竖屏" :value='0'></el-option>
                              <el-option label="横屏/小竖屏" :value='1'></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item v-else label="Lcd屏">
                          <el-select v-model="gateForm.gt_lcd_id" style="width:100%;">
                              <el-option v-for="item in GetLcdDataList" :key="item.dev_id" :label="item.dev_name" :value="item.dev_id"></el-option>
                              <el-option label="无" :value='0'></el-option>
                          </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                      <el-form-item label="相机">
                        <el-select v-model="gateForm.gt_cam_id" style="width:100%;">
                            <el-option label="无" :value='0'></el-option>
                            <el-option v-for="item in GetCamDevDataList" :key="item.dev_id" :label="item.dev_name" :value="item.dev_id"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="所属岗亭">
                        <el-select v-model="gateForm.gt_post_id" style="width:100%;">
                          <el-option label="无" :value='0'></el-option>
                          <el-option v-for="item in PostDataList" :key="item.post_id" :label="item.post_name" :value="item.post_id"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="余位屏">
                        <el-select v-model="gateForm.gt_yw_id" style="width:100%;">
                          <el-option v-for="item in GetYWDataList" :key="item.dev_id" :label="item.dev_name" :value="item.dev_id"></el-option>
                          <el-option label="无" :value='0'></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-collapse-item>
                <el-collapse-item name="2">
                    <template slot="title">
                      <span style="font-size:13px;color:#409EFF"> 岗亭功能</span>
                    </template>
                    <el-row :gutter="10">
                      <el-col :xs="12" :sm="8" :md="6" >
                        <el-checkbox v-model="checked1">启用手动抓拍</el-checkbox>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="6">
                        <el-checkbox v-model="checked2">启用手动处理</el-checkbox>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="6">
                        <el-checkbox v-model="checked3">启用手动开闸</el-checkbox>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="6">
                        <el-checkbox v-model="checked4">启用手动关闸</el-checkbox>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10" style="margin-top:10px">
                      <el-col :xs="12" :sm="8" :md="6">
                        <el-checkbox v-model="checked5">启用车队模式</el-checkbox>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="6">
                        <el-checkbox v-model="checked6">启用ETC</el-checkbox>
                      </el-col>
                      <el-col :xs="12" :sm="12" :md="12" >
                        <el-form-item label="二维码地址">
                            <el-input v-model="gateForm.gt_qrcode_url" ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item name="3">
                    <template slot="title">
                      <span style="font-size:13px;color:#409EFF"> 车辆进出控制</span>
                    </template>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                         <el-form-item label="允许进出卡型">
                            <el-select v-if="dialogVisible" v-model="gateForm.gt_limit_cardtype" collapse-tags multiple style="width:100%;">
                              <el-option v-for="item in CardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="允许进出车型">
                            <el-select v-model="gateForm.gt_limit_cartype" style="width:100%;">
                                <el-option label="全部" :value='0'></el-option>
                                <el-option label="小车" :value='1'></el-option>
                                <el-option label="大车" :value='2'></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                         <el-form-item label="重复进出卡类">
                            <el-select v-if="dialogVisible" v-model="gateForm.gt_inout_repeat" collapse-tags multiple style="width:100%;" :disabled='gateForm.gt_type===0?true:false'>
                                <el-option v-for="item in RepeatCardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="确认放行卡类">
                            <el-select v-if="dialogVisible" v-model="gateForm.gt_confirm_card" collapse-tags multiple style="width:100%;">
                                <el-option v-for="item in CardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                         <el-form-item label="临时车开闸">
                            <el-select v-model="gateForm.gt_temp_opt" style="width:100%;">
                                <el-option :label="gateForm.gt_type===0?'自动放行':'收费为0,自动放行'" :value='0'></el-option>
                                <el-option label="确认放行" :value='1'></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="无入场记录">
                            <el-select v-model="gateForm.gt_csi_plateno_opt" style="width:100%;" :disabled='gateForm.gt_type===0?true:false'>
                                <el-option label="补录出场" :value='0'></el-option>
                                <el-option label="自动放行" :value='1'></el-option>
                                <el-option label="自动匹配" :value='2'></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                         <el-form-item label="入场码">
                            <el-select v-model="gateForm.gt_qrcode_opt_type" style="width:100%;" :disabled='gateForm.gt_type===0?false:true'>
                                <el-option label="扫码入场" :value='0'></el-option>
                                <el-option label="压地感扫码入场" :value='1'></el-option>
                                <el-option label="停用" :value='2'></el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                         <el-form-item label="扫码时段">
                            <el-time-picker is-range v-model="gateForm.gt_qrcode_time" range-separator="-" format='HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' style="width:100%" :disabled='gateForm.gt_type===0?false:true'></el-time-picker>
                          </el-form-item>
                      </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item name="4">
                   <template slot="title">
                      <span style="font-size:13px;color:#409EFF"> Led广告设置</span>
                    </template>
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item label="第一行">
                           <el-input v-model="gateForm.gt_led_firstLine" class="input-with-select" :disabled="gateForm.gt_led_first_state===2?false:true">
                              <el-select v-model="gateForm.gt_led_first_state" slot="prepend" style="width:110px">
                                <el-option label="时间" :value='0'></el-option>
                                <el-option label="余位" :value='1'></el-option>
                                <el-option label="自行编辑" :value='2'></el-option>
                              </el-select>
                               <template slot="append">
                                  <el-select v-model="gateForm.gt_led_first_color" slot="prepend" style="width:80px">
                                    <el-option label="红" :value='0'></el-option>
                                    <el-option label="绿" :value='1'></el-option>
                                    <el-option label="黄" :value='2'></el-option>
                                  </el-select>
                              </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="第三行">
                           <el-input v-model="gateForm.gt_led_thirdLine" class="input-with-select" :disabled="gateForm.gt_led_third_state===2?false:true">
                              <el-select v-model="gateForm.gt_led_third_state" slot="prepend" style="width:110px">
                                <el-option label="时间" :value='0'></el-option>
                                <el-option label="余位" :value='1'></el-option>
                                <el-option label="自行编辑" :value='2'></el-option>
                              </el-select>
                              <template slot="append">
                                  <el-select v-model="gateForm.gt_led_third_color" slot="prepend" style="width:80px">
                                    <el-option label="红" :value='0'></el-option>
                                    <el-option label="绿" :value='1'></el-option>
                                    <el-option label="黄" :value='2'></el-option>
                                  </el-select>
                              </template>
                          </el-input>

                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item label="第二行">
                           <el-input v-model="gateForm.gt_led_secondLine" class="input-with-select" :disabled="gateForm.gt_led_second_state===2?false:true">
                              <el-select v-model="gateForm.gt_led_second_state" slot="prepend" style="width:110px">
                                <el-option label="时间" :value='0'></el-option>
                                <el-option label="余位" :value='1'></el-option>
                                <el-option label="自行编辑" :value='2'></el-option>
                              </el-select>
                              <template slot="append">
                                  <el-select v-model="gateForm.gt_led_second_color" slot="prepend" style="width:80px">
                                    <el-option label="红" :value='0'></el-option>
                                    <el-option label="绿" :value='1'></el-option>
                                    <el-option label="黄" :value='2'></el-option>
                                  </el-select>
                              </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="第四行">
                           <el-input v-model="gateForm.gt_led_fourthLine" class="input-with-select" :disabled="gateForm.gt_led_fourth_state===2?false:true">
                              <el-select v-model="gateForm.gt_led_fourth_state" slot="prepend" style="width:110px">
                                <el-option label="时间" :value='0'></el-option>
                                <el-option label="余位" :value='1'></el-option>
                                <el-option label="自行编辑" :value='2'></el-option>
                              </el-select>
                              <template slot="append">
                                  <el-select v-model="gateForm.gt_led_fourth_color" slot="prepend" style="width:80px">
                                    <el-option label="红" :value='0'></el-option>
                                    <el-option label="绿" :value='1'></el-option>
                                    <el-option label="黄" :value='2'></el-option>
                                  </el-select>
                              </template>
                          </el-input>
                        </el-form-item>
                    </el-col>
                  </el-row>
                </el-collapse-item>
                <el-collapse-item name="5">
                   <template slot="title">
                      <span style="font-size:13px;color:#409EFF"> 车辆进出Led语音显示</span>
                  </template>
                  <el-card>
                    <div slot="header" class="clearfix">
                      <span>长期车进出语音显示</span>
                    </div>
                    <el-form-item v-for="(item,len) in ledForm.LedLongcardListData" :key="item.key" :label="item.label">
                      <el-row>
                        <el-col :xs="24" :sm="12" :md="8" :lg="index<4?4:6" v-for="(team,index) in item.childrens" :key="team.key">
                          <div v-if="index<4"><!--显示-->
                            <el-checkbox v-model="team.value" :label="team.name" @change="checked=>LedCheckboxChanged(checked,len,'longcard')"></el-checkbox>
                          </div>
                          <div v-else><!--语音-->
                            <div v-if="len==4">
                              <el-select v-model="team.volState" v-if="gateForm.gt_type==0" style="width:100%;">
                                <el-option label="欢迎光临" :value='0'></el-option>
                                <el-option label="欢迎回家" :value='1'></el-option>
                                <el-option label="减速慢行" :value='2'></el-option>
                                <el-option label="允许通行" :value='3'></el-option>
                              </el-select>
                              <el-select v-model="team.volState" v-else style="width:100%;">
                                <el-option label="一路顺风" :value='0'></el-option>
                                <el-option label="一路平安" :value='1'></el-option>
                                <el-option label="减速慢行" :value='2'></el-option>
                                <el-option label="允许通行" :value='3'></el-option>
                              </el-select>
                            </div>
                            <div v-else>
                              <el-input v-model="team.text" :disabled="!item.childrens[3].value" style="width:100%"></el-input>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-card>
                  <el-card>
                    <div slot="header" class="clearfix">
                      <span>临时车进出语音显示</span>
                    </div>
                    <el-form-item v-for="(item,len) in ledForm.LedTempcardListData" :key="item.key" :label="item.label">
                      <el-row>
                        <el-col :xs="24" :sm="12" :md="8" :lg="4" v-for="(team,index) in item.childrens" :key="team.key">
                          <div v-if="index<5">
                            <el-checkbox v-model="team.value" :label="team.name" :disabled="ledForm.gt_type==0&&(index==2||index==3)?true:false" @change="checked=>LedCheckboxChanged(checked,len,'tempcard')"></el-checkbox>
                          </div>
                          <div v-else>
                            <div v-if="len==4">
                              <el-select v-model="team.volState" v-if="gateForm.gt_type==0" style="width:100%;">
                                <el-option label="欢迎光临" :value='0'></el-option>
                                <el-option label="欢迎回家" :value='1'></el-option>
                                <el-option label="减速慢行" :value='2'></el-option>
                                <el-option label="允许通行" :value='3'></el-option>
                              </el-select>
                              <el-select v-model="team.volState" v-else style="width:100%;">
                                <el-option label="一路顺风" :value='0'></el-option>
                                <el-option label="一路平安" :value='1'></el-option>
                                <el-option label="减速慢行" :value='2'></el-option>
                                <el-option label="允许通行" :value='3'></el-option>
                              </el-select>
                            </div>
                            <div v-else>
                              <el-input v-model="team.text" :disabled="!item.childrens[4].value" style="width:100%"></el-input>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-card>
                </el-collapse-item>
                <el-collapse-item name="6">
                   <template slot="title">
                      <span style="font-size:13px;color:#409EFF"> 音量设置</span>
                  </template>
                  <el-row :gutter="10">
                    <el-col :span="24">
                      <el-form-item label="白天时段">
                        <el-time-picker is-range v-model="volForm.vc_day_time" range-separator="-" format='HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' :clearable='false' style="width:100%"></el-time-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="24">
                      <el-form-item label="白天音量">
                        <el-slider v-model="volForm.vc_day_size" :min='1' :max='9'></el-slider>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="24">
                      <el-form-item label="夜间音量">
                        <el-slider v-model="volForm.vc_night_size" :min='1' :max='9'></el-slider>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </el-collapse>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible.sync="tcDialogVisible" :append-to-body='true'>
            <el-button type="primary" class="el-icon-plus" @click="AddTimeControl"> 新增</el-button>
            <el-table :data='TimeControlDataList' border style="width: 100%;font-size:13px;margin-top: 1rem;" stripe :highlight-current-row='true'>
              <el-table-column align="center" prop="card_name" label="卡类"></el-table-column>
              <el-table-column align="center" prop="week" label="星期"></el-table-column>
              <el-table-column align="center" prop="time" label="时段"></el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="DelTimeControl(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
              </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button @click="tcDialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="时段选择" :visible.sync="timeDialogVisible" width='50rem' :append-to-body='true'>
          <div>
            <div class="post">
              <span style="width: 3rem;">卡类：</span>
              <el-select v-model="tcForm.lt_card_type" collapse-tags multiple style="width:100%;">
                <el-option v-for="item in CardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
              </el-select>
            </div>
            <div class="post" style="margin-top: 2rem;">
              <span style="width: 3rem;">星期：</span>
                <el-checkbox-group v-model="tcForm.week">
                  <el-checkbox label="星期一"></el-checkbox>
                  <el-checkbox label="星期二"></el-checkbox>
                  <el-checkbox label="星期三"></el-checkbox>
                  <el-checkbox label="星期四"></el-checkbox>
                  <el-checkbox label="星期五"></el-checkbox>
                  <el-checkbox label="星期六"></el-checkbox>
                  <el-checkbox label="星期日"></el-checkbox>
                </el-checkbox-group>
              
            </div>
            <div class="post" style="margin-top: 2rem;">
              <span style="width: 3rem;">时段：</span>
              <el-time-picker v-model="tcForm.lt_begin_time"  placeholder="起始时间" format='HH:mm:ss' value-format='HH:mm'></el-time-picker>
              <span style="margin-left: 0.5rem; margin-right: 0.5rem;">-</span>
              <el-time-picker v-model="tcForm.lt_end_time"  placeholder="结束时间" format='HH:mm:ss' value-format='HH:mm'></el-time-picker>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="timeDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="Confirm">确 定</el-button>
          </span>
      </el-dialog>

    </div>
</template>

<script>
import Vue from 'vue'
import ZkTable from 'vue-table-with-tree-grid'
import util from '../../util/util'
Vue.component('treetable', ZkTable)

export default {
  data: function () {
    return {
      screenWidth: 0,
      activeNames: '1',

      dialogVisible: false,

      tcDialogVisible:false,
      TimeControlDataList:[],
      tcForm:{
        lt_id:0,
        lt_gt_id:0,
        lt_card_type:0,
        week:[],
        lt_begin_time:'00:00',
        lt_end_time:'23:59:59',
        gt_name:''
      },



      timeDialogVisible:false,


      title: '',
      

      TreeGateListData: [],
      GateListData: [],
      ParkDataList: [],
      DevDataList: [],
      PostDataList: [],
      CardDataList: [],
      RepeatCardDataList: [],

      columns: [
        {
          label: '名称',
          prop: 'gt_name'
        },
        {
          label: '所属车场',
          prop: 'gt_pk_name'
        },
        {
          label: '类型',
          type: 'template',
          template: 'gtType'
        },
        {
          label: '相机',
          prop: 'gt_dev_name'
        },
        {
          label: '操作',
          type: 'template',
          template: 'handleEvent'
        }
      ],
      checked1: true,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5:false,
      checked6:false,
      gateForm: {
        gt_id: 0,
        gt_name: '',
        gt_post_id: 0,
        gt_pk_id: 0,
        gt_type: 0,
        gt_no: 1,
        gt_cam_id: 0,
        gt_led_type: 0,
        gt_led_model: 0,
        gt_lcd_id: 0,
        gt_yw_id:0,
        gt_etc_id:0,
        gt_enable_model: 0,
        gt_qrcode_url: '',
        gt_limit_cardtype: [],
        gt_limit_cartype: 0,
        gt_inout_repeat: [],
        gt_confirm_card: [],
        gt_temp_opt: 0,
        gt_csi_plateno_opt: 2,
        gt_qrcode_opt_type: 0,
        gt_qrcode_time: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 0, 0, 0)],
        gt_led_first_state: 2,
        gt_led_firstLine: '自动识别',
        gt_led_second_state: 0,
        gt_led_third_state: 2,
        gt_led_fourth_state: 1,
        gt_led_secondLine: '',
        gt_led_thirdLine: '减速慢行',
        gt_led_fourthLine: '',
        gt_led_first_color: 0,
        gt_led_second_color: 0,
        gt_led_third_color: 0,
        gt_led_fourth_color: 0
      },
      postName: '',
      volForm: {
        vc_id: 0,
        vc_day_time: [new Date(2016, 9, 10, 8, 0, 0), new Date(2016, 9, 10, 21, 59, 59)],
        vc_day_size: 5,
        vc_night_size: 5
      },
      ledForm: {
        led_id: 0,
        LedLongcardListData: [],
        LedTempcardListData: []
      },
      rules: {
        gt_name: [{ required: true, message: '出入口名称', trigger: 'blur' }]
      },
      isFinish: true
    }
  },
  created () {
    this.screenWidth = document.body.clientWidth
    this.GetGateDataList()

    for (let index = 1; index <= 5; index++) {
      const item = {}
      item.label = index < 5 ? '第' + index + '行' : '语音'
      item.key = 'longcard_' + index
      item.childrens = []
      for (let id = 1; id <= 5; id++) {
        const child = {}
        child.key = 'longcard_' + index + id
        child.name = id === 1 ? '车牌号' : (id === 2 ? '车型' : (id === 3 ? '有效期' : (id === 4 ? '欢迎词' : '')))
        child.value = false
        child.text = ''
        child.volState = 0
        item.childrens.push(child)
      }
      this.ledForm.LedLongcardListData.push(item)
    }
    for (let index = 1; index <= 5; index++) {
      const item = {}
      item.label = index < 5 ? '第' + index + '行' : '语音'
      item.key = 'tempcard_' + index
      item.childrens = []
      for (let id = 1; id <= 6; id++) {
        const child = {}
        child.key = 'tempcard_' + index + id
        child.name = id === 1 ? '车牌号' : (id === 2 ? '车型' : (id === 3 ? '停车时长' : (id === 4 ? '收费' : (id === 5 ? '欢迎词' : ''))))
        child.value = false
        child.text = ''
        child.volState = 0
        item.childrens.push(child)
      }
      this.ledForm.LedTempcardListData.push(item)
    }
  },
  computed: {
    GetCamDevDataList () {
      var arr = JSON.parse(JSON.stringify(this.DevDataList))
      var json
      if (arr.length > 0) {
        json = arr.filter(a => a.dev_type === 0)
      }
      return json
    },
    GetLcdDataList () {
      var arr = JSON.parse(JSON.stringify(this.DevDataList))
      var json
      if (arr.length > 0) {
        json = arr.filter(a => a.dev_type === 1)
      }
      return json
    },
    GetYWDataList(){
      var arr = JSON.parse(JSON.stringify(this.DevDataList))
      var json
      if (arr.length > 0) {
        json = arr.filter(a => a.dev_type === 2)
      }
      return json
    },
    GetEtcDataList(){
      var arr = JSON.parse(JSON.stringify(this.DevDataList))
      var json
      if (arr.length > 0) {
        json = arr.filter(a => a.dev_type === 3)
      }
      return json
    }
  },
  methods: {
    GetGateDataList () {
      this.TreeGateListData = []
      this.RepeatCardDataList = []
      util.Get('gate/getgatedatalist').then(res => {
        if (res.rpStatus === 10000) {
          this.ParkDataList = res.list[0]
          this.DevDataList = res.list[2]
          this.PostDataList = res.list[1]
          this.CardDataList = res.list[3]
          this.GateListData = res.list[4]
          // 构造树
          this.PostDataList.forEach(a => {
            var form = {
              gt_id: 0,
              gt_name: a.post_name,
              gt_pk_name: '',
              gt_type: -1,
              gt_dev_name: '',
              children: []
            }
            var arr = this.GateListData.filter(b => b.gt_post_id === a.post_id)
            arr.forEach(b => {
              var dev = this.DevDataList.filter(c => c.dev_id === b.gt_cam_id)
              var park = this.ParkDataList.filter(c => c.pk_id === b.gt_pk_id)
              var f = {
                gt_id: b.gt_id,
                gt_name: b.gt_name,
                gt_pk_name: park.length === 0 ? '' : park[0].pk_name,
                gt_type: b.gt_type,
                gt_dev_name: dev.length === 0 ? '' : dev[0].dev_name
              }
              form.children.push(f)
            })
            
            this.TreeGateListData.push(form)
          })

          this.CardDataList.forEach(a => {
            if (a.card_limit !== null&&a.card_limit!==8) {
              // 重复进出卡类，不能有临时车
              this.RepeatCardDataList.push(a)
            }
          })
        }
      })
    },
    AddClick () {
      if(util.CheckUserAuth('1-3-1')){
        util.Get('gate/getgateNo').then(res => {
          if (res.rpStatus === 10000) {
            this.gateForm.gt_no = parseInt(res.rpMsg)
          }
        })

        this.dialogVisible = true
        this.title = '新增'
        this.gateForm.gt_id = 0
        this.gateForm.gt_name = ''
        this.gateForm.gt_post_id = 0
        this.gateForm.gt_pk_id = 0
        this.gateForm.gt_type = 0
        this.gateForm.gt_led_type = 0
        this.gateForm.gt_yw_id=0
        this.gateForm.gt_cam_id = 0
        this.gateForm.gt_led_model = 0
        this.gateForm.gt_lcd_id = 0
        this.gateForm.gt_etc_id=0
        this.gateForm.gt_qrcode_url = ''
        this.gateForm.gt_enable_model = 0
        this.gateForm.gt_limit_cardtype = []
        this.gateForm.gt_limit_cartype = 0
        this.gateForm.gt_inout_repeat = []
        this.gateForm.gt_confirm_card = []
        this.gateForm.gt_temp_opt = 0
        this.gateForm.gt_csi_plateno_opt = 2
        this.gateForm.gt_qrcode_opt_type = 0
        this.gateForm.gt_qrcode_time = [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 0, 0, 0)]
        this.gateForm.gt_led_first_state = 0
        this.gateForm.gt_led_firstLine = ''
        this.gateForm.gt_led_second_state = 2
        this.gateForm.gt_led_secondLine = '自动识别'
        this.gateForm.gt_led_third_state = 2
        this.gateForm.gt_led_fourth_state = 1
        this.gateForm.gt_led_thirdLine = '减速慢行'
        this.gateForm.gt_led_fourthLine = ''
        this.gateForm.gt_led_first_color = 0
        this.gateForm.gt_led_second_color = 0
        this.gateForm.gt_led_third_color = 0
        this.gateForm.gt_led_fourth_color = 0
        this.checked1 = true
        this.checked2 = true
        this.checked3 = false
        this.checked4 = false
        this.checked5=false
        this.checked6=false

        this.CardDataList.forEach(a => {
          this.gateForm.gt_limit_cardtype.push(a.card_id)

          if (a.card_limit !== 7) {
            this.gateForm.gt_inout_repeat.push(a.card_id)
          }
        })

        this.volForm.vc_id = 0
        this.volForm.vc_day_time = [new Date(2016, 9, 10, 8, 0, 0), new Date(2016, 9, 10, 21, 59, 59)]
        this.volForm.vc_day_size = 5
        this.volForm.vc_night_size = 5

        this.ledForm.led_id = 0
        this.SetLedConfig(null)
      }
    },
    Edit (row) {
      if(util.CheckUserAuth('1-3-2')){
        this.dialogVisible = true
        this.title = '修改'
        var arr = this.GateListData.filter(a => a.gt_id === row.gt_id)

        this.gateForm = JSON.parse(JSON.stringify(arr[0]))

        if (arr[0].gt_qrcode_in_begintime !== null) {
          this.$set(this.gateForm, 'gt_qrcode_time', [util.FormateDate(arr[0].gt_qrcode_in_begintime,2), util.FormateDate(arr[0].gt_qrcode_in_endtime,2)])
        }

        util.Get('gate/getgateotherdata?id=' + row.gt_id).then(res => {
          if (res.rpStatus === 10000) {
            this.gateForm.gt_limit_cardtype = []
            this.gateForm.gt_inout_repeat = []
            this.gateForm.gt_confirm_card = []

            if (res.list[0].length > 0) {
              res.list[0].forEach(a => {
                this.gateForm.gt_limit_cardtype.push(a.limit_card_id)
              })
            }
            res.list[1].forEach(a => {
              this.gateForm.gt_inout_repeat.push(a.rp_card_id)
            })
            res.list[2].forEach(a => {
              this.gateForm.gt_confirm_card.push(a.cf_card_id)
            })
          
            this.volForm = res.list[3][0]
            if (res.list[3][0].vc_day_begin_time !== null) {
              this.$set(this.volForm, 'vc_day_time', [util.FormateDate(res.list[3][0].vc_day_begin_time,2), util.FormateDate(res.list[3][0].vc_day_end_time,2)])
            }
           
            var led = res.list[4][0]
            this.ledForm.led_id = led.led_id
            for (let i = 0; i < this.ledForm.LedLongcardListData.length; i++) {
              let state = 0
              switch (i) {
                case 0:state = led.led_longcard_first_state; break
                case 1:state = led.led_longcard_second_state; break
                case 2:state = led.led_longcard_third_state; break
                case 3:state = led.led_longcard_fourth_state; break
                case 4:state = led.led_longcard_vol_state; break
              }
              const arr = this.ledForm.LedLongcardListData[i].childrens
              if ((state & 0x08) === 0x08) {
                arr[0].value = true
              }
              if ((state & 0x04) === 0x04) {
                arr[1].value = true
              }
              if ((state & 0x02) === 0x02) {
                arr[2].value = true
              }
              if ((state & 0x01) === 0x01) {
                arr[3].value = true
                arr[4].text = i === 0 ? led.led_longcard_first_line : (i === 1 ? led.led_longcard_second_line : (i === 2 ? led.led_longcard_third_line : (i === 3 ? led.led_longcard_fourth_line : '')))
                arr[4].volState = i === 4 ? led.led_longcard_vol_remind : 0
              }
            }
            for (let i = 0; i < this.ledForm.LedTempcardListData.length; i++) {
              let state = 0
              switch (i) {
                case 0:state = led.led_tempcard_first_state; break
                case 1:state = led.led_tempcard_second_state; break
                case 2:state = led.led_tempcard_third_state; break
                case 3:state = led.led_tempcard_fourth_state; break
                case 4:state = led.led_tempcard_vol_state; break
              }
              const arr = this.ledForm.LedTempcardListData[i].childrens
              if ((state & 0x10) === 0x10) {
                arr[0].value = true
              }
              if ((state & 0x08) === 0x08) {
                arr[1].value = true
              }
              if ((state & 0x04) === 0x04) {
                arr[2].value = true
              }
              if ((state & 0x02) === 0x02) {
                arr[3].value = true
              }
              if ((state & 0x01) === 0x01) {
                arr[4].value = true
                arr[5].text = i === 0 ? led.led_tempcard_first_line : (i === 1 ? led.led_tempcard_second_line : (i === 2 ? led.led_tempcard_third_line : (i === 3 ? led.led_tempcard_fourth_line : '')))
                arr[5].volState = i === 4 ? led.led_tempcard_vol_remind : 0
              }
            }
          }
        })
        
        this.checked1 = false
        this.checked2 = false
        this.checked3 = false
        this.checked4 = false
        this.checked5 = false
        this.checked6=false
        if ((arr[0].gt_enable_model & 0x01) === 0x01) { this.checked1 = true }
        if ((arr[0].gt_enable_model & 0x02) === 0x02) { this.checked2 = true }
        if ((arr[0].gt_enable_model & 0x04) === 0x04) { this.checked3 = true }
        if ((arr[0].gt_enable_model & 0x08) === 0x08) { this.checked4 = true }
        if ((arr[0].gt_enable_model & 0x10) === 0x10) { this.checked5 = true }
        if ((arr[0].gt_enable_model & 0x20) === 0x20) { this.checked6 = true }
      }
    },
    Del (row) {
      if(util.CheckUserAuth('1-3-3')){
        this.$confirm('确定删除通道“' + row.gt_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('gate/del?id=' + row.gt_id+'&name='+row.gt_name).then(res => {
            if (res.rpStatus === 10000) { this.GetGateDataList() } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
          })
        }).catch(e => e)
      }
    },
    SetLedConfig (led) {
      this.ledForm.LedLongcardListData.forEach(a => {
        a.value = false
        a.text = ''
        a.volState = 0
      })
      this.ledForm.LedTempcardListData.forEach(a => {
        a.value = false
        a.text = ''
        a.volState = 0
      })

      if (led === null || led.length === 0) {
        this.ledForm.LedLongcardListData[0].childrens[0].value = true
        this.ledForm.LedLongcardListData[1].childrens[3].value = true
        this.ledForm.LedLongcardListData[2].childrens[1].value = true
        this.ledForm.LedLongcardListData[3].childrens[2].value = true
        this.ledForm.LedLongcardListData[4].childrens[0].value = true
        this.ledForm.LedLongcardListData[4].childrens[1].value = true
        this.ledForm.LedLongcardListData[4].childrens[3].value = true

        this.ledForm.LedTempcardListData[0].childrens[0].value = true
        this.ledForm.LedTempcardListData[1].childrens[4].value = true
        this.ledForm.LedTempcardListData[2].childrens[1].value = true
        this.ledForm.LedTempcardListData[4].childrens[0].value = true
        this.ledForm.LedTempcardListData[4].childrens[1].value = true
        this.ledForm.LedTempcardListData[4].childrens[4].value = true

        if (this.gateForm.gt_type === 0) {
          this.ledForm.LedLongcardListData[1].childrens[4].text = '欢迎光临'
          this.ledForm.LedLongcardListData[4].childrens[4].volState = 0

          this.ledForm.LedTempcardListData[1].childrens[5].text = '欢迎光临'
          this.ledForm.LedTempcardListData[0].childrens[0].volState = 0
        } else {
          this.ledForm.LedLongcardListData[1].childrens[4].text = '一路顺风'
          this.ledForm.LedLongcardListData[4].childrens[4].volState = 0

          this.ledForm.LedTempcardListData[1].childrens[3].value = true
          this.ledForm.LedTempcardListData[1].childrens[4].value = true
          this.ledForm.LedTempcardListData[1].childrens[5].text = '一路顺风'
          this.ledForm.LedTempcardListData[3].childrens[2].value = true
          this.ledForm.LedTempcardListData[4].childrens[3].value = true
        }
      }
    },
    // led
    LedCheckboxChanged (checked, rowNo, name) {
      if (checked) {
        if (name === 'longcard') {
          for (const index in this.ledForm.LedLongcardListData) {
            if (index < 4 && rowNo !== 4) {
              if (index < rowNo || index > rowNo) {
                this.ledForm.LedLongcardListData[index].childrens[3].value = false
              }
            }
          }
        } else {
          for (const index in this.ledForm.LedTempcardListData) {
            if (index < 4 && rowNo !== 4) {
              if (index < rowNo || index > rowNo) {
                this.ledForm.LedTempcardListData[index].childrens[4].value = false
              }
            }
          }
        }
      }
    },
    GateTypeChanged (val) {
      if (val === 0) {
        this.gateForm.gt_led_first_state = 0
        this.gateForm.gt_led_firstLine = ''
        this.gateForm.gt_led_second_state = 2
        this.gateForm.gt_led_secondLine = '自动识别'
        this.gateForm.gt_led_third_state = 2
        this.gateForm.gt_led_thirdLine = '减速慢行'
        this.gateForm.gt_led_fourth_state = 1
        this.gateForm.gt_led_fourthLine = ''
      } else {
        this.gateForm.gt_led_first_state = 0
        this.gateForm.gt_led_firstLine = ''
        this.gateForm.gt_led_second_state = 2
        this.gateForm.gt_led_secondLine = '一路顺风'
        this.gateForm.gt_led_third_state = 2
        this.gateForm.gt_led_fourth_state = 2
        this.gateForm.gt_led_thirdLine = '减速慢行'
        this.gateForm.gt_led_fourthLine = '一车一杆'
      }
      this.SetLedConfig(null)
    },
    submit () {
      this.$refs.gateForm.validate((valid) => {
        if (valid) {
          if (this.gateForm.gt_cam_id === 0) {
            this.$message.error('请选择通道相机')
            return
          }
          if (this.gateForm.gt_pk_id === 0) {
            this.$message.error('请选择通道所属车场')
            return
          }
          if (this.gateForm.gt_post_id === 0) {
            this.$message.error('请选择通道所属岗亭')
            return
          }

          var enabelModel = 0
          if (this.checked1) { enabelModel += 1 }
          if (this.checked2) { enabelModel += 2 }
          if (this.checked3) { enabelModel += 4 }
          if (this.checked4) { enabelModel += 8 }
          if (this.checked5) { enabelModel += 16 }
          if (this.checked6) { enabelModel += 32 }
          this.gateForm.gt_enable_model = enabelModel

          var form = Object.assign(this.gateForm, this.volForm, this.ledForm)
          util.Post('gate/edit', form).then(res => {
            if (res.rpStatus === 10000) {
              this.GetGateDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('gate').then(res=>{
          this.isFinish=true
        })
      }
    },

    //时段控制
    TimeControl(row){
      if(util.CheckUserAuth('1-3-4')){
        this.tcDialogVisible=true
        this.title='禁止进出时段控制'
        this.tcForm.lt_gt_id=row.gt_id
        this.tcForm.gt_name=row.gt_name
        this.GetTimeControl(row.gt_id)
      }
    },
    GetTimeControl(id){
      util.Get('gate/gettimecontrolDataList?id='+id).then(res=>{
        if(res.rpStatus===10000){
          let list=[]
          res.list.forEach(item=>{
              let arr= this.CardDataList.filter(a=>a.card_id===item.lt_card_type) 
              let form={}
              form.card_name=arr[0].card_name
              form.week=''
              if((item.lt_week&0x01)===1){form.week='星期一，'}
              if((item.lt_week&0x02)===2){form.week+='星期二，'}
              if((item.lt_week&0x04)===4){form.week+='星期三，'}
              if((item.lt_week&0x08)===8){form.week+='星期四，'}
              if((item.lt_week&0x10)===0x10){form.week+='星期五，'}
              if((item.lt_week&0x20)===0x20){form.week+='星期六，'}
              if((item.lt_week&0x40)===0x40){form.week+='星期日，'}
              form.week=form.week.substring(0,form.week.length-1)
              form.time=util.FormateDate(item.lt_begin_time,3)+' - '+util.FormateDate(item.lt_end_time,3)
              form.lt_id=item.lt_id
              form.gt_id=item.lt_gt_id
              list.push(form)
          })
          this.TimeControlDataList=list
        }
      })
    },
    AddTimeControl(){
      this.timeDialogVisible=true
      this.tcForm.lt_card_type=0
      this.tcForm.week=[]
      this.tcForm.lt_begin_time='00:00'
      this.tcForm.lt_end_time="23:59"
    },
    Confirm(){
      if(this.tcForm.lt_card_type.length>0){
        if(!this.tcForm.lt_begin_time||!this.tcForm.lt_end_time){
          this.$message.error('请输入禁止出入时段')
          return
        }
      }

      this.tcForm.lt_week=0
      let week=0
      if(this.tcForm.week.length>0){
        this.tcForm.week.forEach(item=>{
          if(item==='星期一'){week=1}
          if(item==='星期二'){week+=2}
          if(item==='星期三'){week+=4}
          if(item==='星期四'){week+=8}
          if(item==='星期五'){week+=16}
          if(item==='星期六'){week+=32}
          if(item==='星期日'){week+=64}
        })
        this.tcForm.lt_week=week
      }
      util.Post("gate/edittimecontrol",this.tcForm).then(res=>{
          if(res.rpStatus===10000){
            this.timeDialogVisible=false
            this.GetTimeControl(this.tcForm.lt_gt_id)
          }
      })
    },

    DelTimeControl(row){
        this.$confirm('确定删除卡类“' + row.card_name + '”时段限制？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tcForm.card_name=row.card_name
          this.tcForm.lt_id=row.lt_id

          util.Post("gate/deltimecontrol",{
            lt_id:row.lt_id,
            card_name:row.card_name,
            gt_name:this.tcForm.gt_name
          }).then(res=>{
              if(res.rpStatus===10000){
                this.GetTimeControl(this.tcForm.lt_gt_id)
              }
          })
        }).catch(e => e)
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}
.treetable{
    margin-top: 10px;
}
.info{
   display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.post{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
}
.btn{
  height: 30px;
  width: 45px;
  margin-left: 10px;
  margin-top: 2px;
  align-items: center;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
.vol{
  display: flex;
    flex-direction: row;

    align-items: center;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
